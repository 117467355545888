/*********************************************
  :: Site Header
********************************************/
.site-header {
	position: relative;
	width: 100%;
	height: $header-height-mobile;
	z-index: 99;

	background: $color-3;

	@include mappy-bp(mediumish){
		height: $header-height-desktop;

		background: url('/images/title-banner.png') no-repeat center;
		background-size: cover;
	}

	.content-max-width {
		@include mappy-bp(largeish){ max-width: 1270px; }
	}

	.logo {
		position: absolute;
		top: 15px;
		left: 3%;
		bottom: 5px;
		transition: none;

		@include mappy-bp(mediumish){
			top: 35px;
			left: 10px;
			width: 170px;
		}

		img {
			width: 100%;
			height: 30px;
			@include mappy-bp(mediumish){
				height: 55px;	
			}
		}
	}

	.hamburger {
        position: absolute;
        top: 21px; 
		right: 3%;

		width: 30px;
    	height: 20px;

		@include mappy-bp(mediumish){
			top: 45px;
			right: 5px;
		}

		span {
			position: absolute;
			top: 0;
			left: 0;
			width: 30px;
			height: 3px;

			background: white;

			transform: rotate(0deg);
			transform-origin: center;
			transition: all 0.3s ease-in-out;
		}

		span:nth-of-type(2) {
			top: 49%;
		}

		span:nth-of-type(3) {
			top: 100%;
		}

        &:focus {
            outline: none;
        }
    }
}
/********************************************/

body.open-menu {
	.hamburger {
		z-index: 999999;

		span {background: black; }

		span:nth-of-type(1) { top: 50%; transform: rotate(45deg); }

		span:nth-of-type(2) { display: none; }

		span:nth-of-type(3) { top: 50%; transform: rotate(-45deg); }
	}
}

/********************************************/

.site-header.home {
	@include mappy-bp(mediumish){
		background: transparent;
	}
}

/********************************************/

.site-header.detail {
	@include mappy-bp(mediumish){
		height: 115px;
		background: $color-3;
	}
}

/********************************************/

.site-header.news {
	@include mappy-bp(mediumish){
		height: 115px;
		background: transparent;
	}
}