/*********************************************
:: Home Page                   
********************************************/
.page-home {

    .hero-container {
        position: relative;
        overflow: hidden;

        margin-top: -$header-height-mobile;
    	width: 100%;
        height: 350px;

        background: url('/images/title-banner.png') no-repeat 70% 100%;
        background-size: cover;

        @include mappy-bp(medium){ height: auto; }
        @include mappy-bp(mediumish){
            margin-top: -$header-height-desktop;
        }
        @include mappy-bp(largeish){
            height: 650px;
        }

        .vid-wrap {
            position: relative;
            width: 100%;
            height: 350px;

            @include mappy-bp(medium){
                height: auto;
                padding: 56.25% 0 0 0;
            }
        }

        .video {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 350px;

            object-fit: cover;

            @include mappy-bp(small){ height: 100%; }
            // @include mappy-bp(medium){ height: 450px; }
            // @include mappy-bp(largeish){ height: 650px; }
        }

    	.text {
    		position: absolute;
            top: 115px;
            right: 0;
            left: 0;
            
            width: 100%;
            max-width: 340px;
            margin: auto;

            text-align: center;

    		@include mappy-bp(medium){
    			top: 215px;

                max-width: 570px;
    		}

    		h1 {
                margin-bottom: 13px;

    			font-size: 32px;
    			font-weight: 700;
                line-height: 35px;
    			color: white;

    			@include mappy-bp(mediumish){
                    margin-bottom: 26px;

    				font-size: 66px;
                    line-height: 70px;
    			}
    		}

            h2 {
                margin-bottom: 45px;

                font-size: 13px;
                font-weight: 300;
    			line-height: 20px;
    			color: white;

    			@include mappy-bp(mediumish){
                    font-size: 20px;
    			    line-height: 30px;
    			}
            }
    	}
    }
    /********************************************/
    .services-container {
        text-align: left;

        article {
            width: 100%;

            background-size: 101% 101%;
            background-position: center;
            background-repeat: no-repeat;
            transition: background-size 0.3s ease-in-out;

            @include mappy-bp(medium){
                width: 50%;
                height: 225px;
            }
            @include mappy-bp(mediumish){
                width: 33.333%;
                height: auto;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;

                @include mappy-bp(medium){ height: 225px; }
                @include mappy-bp(mediumish){ height: 370px; }
                @include mappy-bp(huge){ height: 480px; }
            }

            .wrap {
                padding: 60px 50px;
                text-align: center;

                @include mappy-bp(medium){
                    padding: 0px 50px;
                }
                @include mappy-bp(largeish){
                    padding: 0px 70px;
                }

                h4 {
                    margin-bottom: 15px;

                    font-size: 20px;
                    font-weight: 700;
                    letter-spacing: 3px;
                    line-height: 30px;
                    text-transform: uppercase;
                    color: white;

                    @include mappy-bp(mediumish){
                        font-size: 24px;
                        letter-spacing: 3.7px;
                    }
                }

                h3 {
                    margin-bottom: 0;
                    
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 2px;
                    line-height: 25px;
                    text-transform: uppercase;
                    color: $color-3;
                }
            }
        }

        article:hover {
            background-size: 150% 150%;
        }

        article.quote {
            padding: 40px;

            @include mappy-bp(medium){
                padding: 35px;
            }
            @include mappy-bp(mediumish){
                height: 370px;
                padding: 90px 50px 0;
            }
            @include mappy-bp(huge){
                height: 480px;
                padding: 90px 70px 0;
            }

            h3 {
                margin-bottom: 15px;

                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.5px;
                color: $color-3;

                @include mappy-bp(huge){
                    margin-bottom: 35px;

                    font-size: 36px;
                    line-height: 40px;
                }
            }

            p.author {
                font-size: 16px;
                line-height: 25px;
                text-align: right;
                color: $color-3;
            }

            &:last-of-type {
                background: black;

                h3, p { color: white; }
            }
        }
    }
    /********************************************/
    .quote-container {
        padding: 40px 6%;
        text-align: left;

        .content-narrow-width {
            max-width: 280px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            text-align: center;

            @include mappy-bp(medium){
                max-width: 320px;
            }
        }

        @include mappy-bp(mediumish){
            padding: 115px 0;
        }

        img {
            width: 45px;
            margin: 0 25px 20px 0;
            @include mappy-bp(mediumish){
                width: 50px;
                margin: 5px 50px 20px 0;
            }
        }

        p {
            margin: 0;
            width: calc(100% - 70px);

            font-size: 17px;
            font-weight: 700;
            font-style: italic;
            line-height: 25px;
            color: $color-1;

            @include mappy-bp(mediumish){
                width: calc(100% - 100px);

                font-size: 22px;
                line-height: 35px;
            }
        }
    }
    /********************************************/
    .where-container {
        padding: 40px 0;

        text-align: center;

        background: #f6f6f6;

        @include mappy-bp(mediumish){
            padding: 75px 0;
        }

        h3 {
            margin-bottom: 50px;

            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            color: $color-3;
        }

        .slick-list {
            max-width: 1156px;
        }

        article {
            height: 100px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                img {
                    max-height: 100px;
                    filter: grayscale(100%);
                }
            }
        }
    }
    /********************************************/
    
}
