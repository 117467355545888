/*********************************************
:: Buttons                             
********************************************/

button {
    outline: none;
}

/*******************************************/
// Default Button

button.button,
input.button,
a.button {
    display: inline-block;
    height: 40px;
    padding: 0px 20px;

    font-family: $font-family-2;
    font-size: 15px;
    font-weight: $font-semibold;
    line-height: 38px;
    text-decoration: none;
    letter-spacing: 2px;
    text-align: center;
    color: white;

    background: $color-1;
    border: 1px solid $color-1;
    transition: all .4s ease-in-out;

    &:hover {
		color: $color-1;
        background: white;
    }
}

/*******************************************/
// Button Colors 

.button.white {
    color: black;
    background: white;
    border-color: white;

    @include mappy-bp(medium){
        &:hover, &:focus, &:active{
			background: black;
            color:white;
        }
    }
}

.button.black {
    color: white;
    background: black;
    border-color: black;

    @include mappy-bp(medium){
        &:hover, &:focus, &:active{
			background: white;
            color:black;
        }
    }
}

/*******************************************/
// Disabled Button Styles

.button-disabled,
.button[disabled],
button[disabled],
button[type="submit"][disabled],
input[type="submit"][disabled],
input[type="button"][disabled],
input[type="reset"][disabled]{
	color:white;
	opacity: 0.5;
	cursor: default;
}

