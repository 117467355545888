/*********************************************
:: Services Page                   
********************************************/
.page-services {

    .body-container {
        text-align: center;
        padding: 31px 6% 54px;

        @include mappy-bp(mediumish){
            padding: 31px 0 54px;
        }

        .content-narrow-width {
            max-width: 571px;
            margin: auto;
        }

        .wysiwyg {
            text-align: center;
        }
    }
    /********************************************/
    .services-container {
        article {
            width: 100%;

            background-size: 101% 101%;
            background-position: center;
            background-repeat: no-repeat;
            transition: background-size 0.3s ease-in-out;

            @include mappy-bp(medium){
                width: 50%;
                height: 240px;
            }
            @include mappy-bp(mediumish){
                width: 25%;
                height: 360px;
            }

            .wrap {
                padding: 60px 50px;

                text-align: center;

                @include mappy-bp(medium){
                    min-height: 225px;
                    padding: 77.5px 50px;
                }
                @include mappy-bp(mediumish){
                    min-height: 360px;
                    padding: 140px 55px;
                }

                h4 {
                    margin-bottom: 15px;

                    font-size: 19px;
                    font-weight: 700;
                    letter-spacing: 2px;
                    line-height: 25px;
                    text-transform: uppercase;
                    color: white;
                }

                h3 {
                    margin-bottom: 0;
                    
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 2px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: $color-3;
                }
            }
        }

        article:hover {
            background-size: 150% 150%;
        }
    }
    /********************************************/
    
}
