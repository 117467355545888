/*********************************************
:: News Page                   
********************************************/
.page-news {

    .body-container {
        padding: 30px 0 60px;
        text-align: left;

        @include mappy-bp(medium){
            padding: 36px 0 110px;
        }

        article {
            position: relative;
            width: 100%;
            margin-bottom: 32px;

            @include mappy-bp(medium){
                display: flex;
            }
            @include mappy-bp(mediumish){
                width: 970px;
            }

            .img-wrap {
                width: 100%;
                max-width: 270px;
                font-size: 0;

                @include mappy-bp(mediumish){
                    width: 370px;
                    max-width: 370px;
                }

                img { width: 100%; }
            }

            .content {
                width: 100%;
                padding: 0 0 0 20px;;

                @include mappy-bp(mediumish){
                    width: calc(100% - 370px);
                    padding: 0 0 0 30px;
                }

                h3 {
                    margin-bottom: 5px;

                    font-size: 19px;
                    font-weight: 700;
                    line-height: 25px;
                    color: $color-3;
                }

                .date {
                    margin-bottom: 16px;

                    font-size: 13px;
                    letter-spacing: 1px;
                    line-height: 25px;color: $color-2;
                    text-transform: uppercase;
                }

                p { margin-bottom: 20px; }

                a.link-to {
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    line-height: 20px;
                    text-transform: uppercase;

                    img {
                        position: relative;
                        top: -1px;
                        transform: rotate(0deg);
                    }
                }
            }
        }

        article.to-news {
            .content .link-to {
                img {
                    top: 1px;
                    transform: rotate(180deg);
                }
            }
        }
    }
    /********************************************/
}
