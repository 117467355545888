/*********************************************
:: Title Module                    
********************************************/
.title-module {
	position: relative;
	top: 0;
	left: 0;
	right: 0;

	margin: auto;
	padding: 30px 0;
	background: #f5f5f5;

	@include mappy-bp(mediumish){
		top: -75px;
		z-index: 99;

		display: inline-block;
		width: auto;
		padding: 0;

		background: transparent;
	}

	h1 {
		margin: 0;

		font-size: 24px;
		font-weight: $font-bold;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.5px;
		color: $color-1;

		@include mappy-bp(mediumish){
			font-size: 36px;
			line-height: 40px;
			color: white;
		}
	}
}
/********************************************/