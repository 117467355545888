/*********************************************
:: Default Page                   
********************************************/
.page-default {

    .body-container {
        padding: 30px 0 60px;

        @include mappy-bp(medium){
            padding: 36px 0 110px;
        }

        .content {
            position: relative;
            width: 100%;

            @include mappy-bp(medium){
                width: calc(100% - 170px);
                padding: 0 30px 0 100px;
            
                &:before {
                    content: '';
                    position: absolute;
                    top: 9px;
                    left: 0;

                    width: 70px;
                    height: 5px;

                    background: $color-1;
                }
            }
            @include mappy-bp(mediumish){
                width: calc(100% - 370px);
                padding: 0 130px 0 100px;
            }
        }

        .hero {
            width: 100%;

            @include mappy-bp(medium){ width: 170px; }
            @include mappy-bp(mediumish){ width: 370px; }
        }
    }
    /********************************************/
}
