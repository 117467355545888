/*********************************************
:: Contact Page                   
********************************************/
.page-contact {

    .body-container {
        padding: 36px 0 100px;
        text-align: left;

        .col1 {
            float: left;
            width: 100%;
            margin-bottom: 30px;

            @include mappy-bp(mediumish) {
                width: 500px;
                margin-bottom: 0;
            }

            a { display: block; }

            .phone {
                margin-bottom: 19px;

                font-size: 25px;
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 30px;
                color: $color-1;
            }

            .email {
                margin-bottom: 19px;

                font-size: 18px;
                font-weight: 700;
                line-height: 21px;
                color: $color-3;

                @include mappy-bp(mediumish) { margin-bottom: 39px; }
            }

        }

        .col2 {
            float: left;
            width: 100%;
            margin-top: -2px;

            @include mappy-bp(mediumish) {
                width: calc(100% - 500px);
            }
        }
    }
    /********************************************/ 
}