/*********************************************
:: Pagination Module                    
********************************************/
.pagination-module {
	margin: 20px 0 0;
	padding: 24px 0;

	text-align: center;

	border-top: 1px solid #e3e3e3;

	ul {
		display: inline-block;
		width: 100%;
		height: 34px;
	    margin: 0;
		padding: 0;

		text-align: center;

		@include mappy-bp(mediumish){
			margin: 0px;
		}

		li {
			min-width: 21px;
			height: 21px;
			display: inline-block;
			margin: 0;

			a {
				font-size: 18px;
				letter-spacing: 1px;
				line-height: 20px;
				color: $color-1;
			}

			span {
				font-size: 18px;
				color: $color-1;
			}

			&.active {
				background: $color-1;

				a {
					color: $color-2;
				}
			}
		}

		li.left {
			float: left;
		}
		li.right {
			float: right;
		}
		li.end {
			a, span {
				color: #a1a1a1;
			}
		}
	}
	/********************************************/

}
/********************************************/

