/*********************************************
:: Forms
********************************************/

form {
}

legend {
    margin-bottom: 15px;
    @extend h2;
}

label {
    display: inline-block;
    width: 100%;
    margin-bottom: 2px;

    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
    color: $color-3;
}

/*******************************************/
// Disabled Field Styles
.field-disabled,
input[disabled],
textarea[disabled],
select[disabled],
input[readonly],
textarea[readonly],
select[readonly] {
    opacity: 0.5;
    cursor: default;
}

/*******************************************/
// Core Field Styles
input, textarea, select {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 6px 15px;

    font-size: 14px;
    line-height: 26px;
    
    background: white;
    border: 1px solid #E3E3E3;
    border-radius: 0;

    outline: none;
    -webkit-appearance: none;
    transition: all .5s ease-in-out;

    &:hover {
        background: #f1f1f1;
        border: 1px solid $color-2;

        transition: all .5s ease-in;
    }

}

/*******************************************/
// Misc Field Styles
*::placeholder {
    font-family: $font-family-2;
    color: $color-1;
}

textarea, select[multiple] {
    height: 162px;
    resize: none;
}

input[type=checkbox], input[type=radio] {
    height: auto;
}

/*******************************************/
// Custom Checkbox/Radio Styles (no JS)
.form-field .custom-checkbox-radio {
    float: left;
    width: auto;
    margin: 0;
    padding: 0 0 5px;

    font-weight: 400;
    line-height: 24px;
    cursor: pointer;

    input {
      display: inline-block;
      vertical-align: top;
      width: 15px;
      height: 15px;
      margin: 2px 5px 0 0;
      padding: 0;

      background: none;
      border: none;
      cursor: pointer;
    }

}

/*******************************************/
// Form Field Container
.form-field {
    float:left;
    width: 100%;
    padding: 0 0 25px;
}

/*******************************************/
// Button Field Container
.button-field {
    float:left;
    width: 100%;
    margin: -10px 0 15px;
    padding: 0 0 15px;

    text-align: center;

    button,
    input[type="submit"],
    input[type="reset"] {
        float:right;
        min-width: 104px;

        text-transform: uppercase;

        transition: all .4s ease-in-out;
    }
}

/*******************************************/
// Form Fields Stack

.form-fields-stack {
    lost-utility: clearfix;

    &.two-column {
        .form-field {
            width: 100%;
            @include mappy-bp(mediumish){
                lost-column: 1/2 2 20px;
            }
        }
    }

    &.three-column {
        .form-field {
            width: 100%;
            @include mappy-bp(mediumish){
                lost-column: 1/3 3 20px;
            }
        }
    }

    &.two-thirds {
        @include mappy-bp(mediumish){
            .form-field:first-of-type {
                width: 265px;
            }
            .form-field:last-of-type {
                float: right;
                width: calc(100% - 285px);
            }
        }
    }
}

/*******************************************/
// Form Feedback
.form-field.error input,
.form-field.error textarea,
.form-field.error select,
.form-field.error .SumoSelect>.CaptionCont {
    border-color: $color-attention;
}

.form-field.valid input,
.form-field.valid textarea,
.form-field.valid select,
.form-field.valid .SumoSelect>.CaptionCont{
    color: black;
    border-color: #CCCCCC;
}

label.error, label.error-SumoSelect-label, .php-error li {
    margin: 10px 0 0;
    font-weight: $font-bold;
    color: $color-attention !important;
}

.form-note {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: $font-normal;
    line-height: 1;
}
