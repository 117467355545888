/*********************************************
  :: Main Mob Menu                             
********************************************/
body.open-menu {
    #main-nav {
        right: 0;

        .menu-close-icon {
           left: -52px; 
        }
    }
}

#main-nav {
    position: fixed;
    top: 0;
    bottom: 0; 
    right: -100%;
    z-index: 9999;

    width: calc(100% - 52px);
    max-width: 323px;

    background: white;

    transition: all .25s;

    @include mappy-bp(mediumish){
        position: absolute;
        width: 33.33%;
        max-width: 33.33%;
        height: 650px;
    }

    .menu-close-icon {
        position: absolute;
        top: 0;
        left: 0;

        width: 52px;
        height: 56px;

        background-color: $color-1; 
        border: none;
        outline: none;

        span {
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: white;
        }
    }

    .menu-content {
        overflow-y: auto;
        &::-webkit-scrollbar { 
            display: none; 
        }
    }

    ul {
        padding: 130px 30px 0;
        @include mappy-bp(mediumish){
            padding: 130px 75px 0;
        }
    }

    li {
        width: 100%;
        margin: 0 0 51px;

        a {
            font-family: $font-family-1;
            font-size: 24px;
            font-weight: $font-bold;
            line-height: 29px;
            // text-transform: uppercase;
            text-align: left;
            color: $color-3;
        }  

        ul {
            display: none;
            padding: 15px 0 0 15px;
        }

        li:last-child {
            border-bottom: none;
        }
    }
}