/*********************************************
:: Detail Templates Page                   
********************************************/
.page-detail-templates {

    .body-container {
        text-align: left;

        .background-image {
            width: 100%;
            height: 150px;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @include mappy-bp(medium){
                height: 325px;
            }
        }

        .content-max-width {
            width: 92%;
            margin: -100px auto 0;
            padding: 75px 6%;

            background: white;

            @include mappy-bp(medium){
                margin: -250px auto 0;
                padding: 76px 100px 110px;
            }
            @include mappy-bp(mediumish){ 
                width: 100%;
                padding: 76px 100px 110px;
            }
        }

        .title {
            width: 100%;
            padding-right: 30px;

            @include mappy-bp(mediumish){ width: calc(100% - 470px); }
            @include mappy-bp(largeish){ width: calc(100% - 570px); }

            h2, .date {
                margin-bottom: 10px;

                font-size: 18px;
                font-weight: 300;
                line-height: 25px;
                text-transform: uppercase;
                letter-spacing: 3px;
                color: $color-3;
            }

            h1 {
                margin-bottom: 34px;

                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                color: $color-3;

                @include mappy-bp(medium){
                    font-size: 37px;
                    line-height: 45px;
                }
            }

            .back {
                position: absolute;
                top: 20px;
                right: 20px;

                font-size: 14px;
                font-weight: 700;
                letter-spacing: 1px;
                line-height: 20px;
                text-transform: uppercase;
                color: $color-1;

                // @include mappy-bp(medium){ 
                //     top: 20px;
                // }

                @include mappy-bp(mediumish){ 
                    position: static;
                }

                img {
                    position: relative;
                    top: 2px;
                    margin: 0 3px 0 0;
                }
            }
        }

        .content {
            width: 100%;
            @include mappy-bp(mediumish){ width: 470px; }
            @include mappy-bp(largeish){ width: 570px; }

            .hero {
                width: 100%;
                margin-bottom: 40px;
            }

            .wysiwyg { margin-bottom: 40px; }
        }
    }
    /********************************************/
}
/********************************************/

.page-detail-templates.news {
    margin-top: -60px;
    @include mappy-bp(mediumish){ 
        margin-top: -115px;
    }

    .background-image {
        height: 210px;
        @include mappy-bp(mediumish){  height: 440px; }
    }
}
/********************************************/
