/*********************************************
  :: Site Footer
********************************************/
.site-footer {
    width: 100%;
    min-height: $footer-height-mobile;
    padding: 50px 0 25px;

    background: $color-3;
    border-top: 5px solid $color-1;
    
    @include mappy-bp(mediumish){
        min-height: $footer-height-desktop;
    }

    .links {
        margin-bottom: 40px;
        text-align: center;

        @include mappy-bp(mediumish){
            margin-bottom: 105px;
            text-align: left;
        }
    
        .logo {
            display: none;

            @include mappy-bp(mediumish){
                display: block;
                float: left;
            }
        }

        .contacts {
            text-align: center;

            @include mappy-bp(mediumish){
                float: right;
                text-align: right;
            }

            a.phone {
                display: block;
                margin-bottom: 21px;

                font-size: 23px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 1px;
                color: white;
            }

            a.email {
                display: block;
                margin-bottom: 29px;

                font-size: 17px;
                line-height: 20px;
                color: $color-1;
            }
        }
    }

    .footer-legal {
        p, a {
            display: inline-block;
            width: 100%;

            font-size: 10px;
            line-height: 15px;
            text-align: center;
            color: white;

            @include mappy-bp(mediumish){
                width: auto;
            }
        }
 
        p.copyright {
            margin-bottom: 5px;

            @include mappy-bp(mediumish){
                float:left;
                margin-bottom: 0px;
            }
        }

        a.attribution {
            @include mappy-bp(mediumish){
                float:right;
            }
        }
    }
}
